import { DropzoneAreaFile, DropzoneAreaRequiredProps } from '../DropzoneArea';
import { S3MultipartDropzoneAreaOnFileUploaded } from '../S3MultipartDropzoneArea';

export type DropzoneHelperFile = {
  activeTab: string;
} & DropzoneAreaFile;

export interface DropzoneHelperFiles {
  [id: string]: DropzoneHelperFile;
}

export type DropzoneHelperFileId = string;

export type DropzoneHelperFileIds = DropzoneHelperFileId[];

export type DropzoneHelperRemovedFileIds = string[];

export interface DropzoneHelperWithInitialValueProps {
  initialFiles: DropzoneHelperFiles;
  initialFileIds: DropzoneHelperFileIds;
}

export interface DropzoneHelperWithoutInitialValueProps {
  initialFiles?: never;
  initialFileIds?: never;
}

export type DropzoneHelperRequiredProps = {
  accept?: string;
  withoutTabs?: boolean;
  withoutDownload?: boolean;
  withoutDropzoneArea?: boolean;
  withoutRemoveButton?: boolean;
  withoutStatus?: boolean;
  maxFiles?: number;
  preventMaxFilesOverload?: boolean;
  disabled?: boolean;
  onChange?: (value: DropzoneHelperFileIds) => void;
  onChangeFiles?: (files: DropzoneHelperFiles) => void;
  onRemoveFile?: (id: string) => void;
  onFileCreated?: (id: string) => void;
  onFileUploaded?: S3MultipartDropzoneAreaOnFileUploaded;
  withFullscreenDropzone?: boolean;
  resetStateOnInitialFilesChange?: boolean;
  withLd?: boolean;
} & DropzoneAreaRequiredProps &
  (
    | DropzoneHelperWithInitialValueProps
    | DropzoneHelperWithoutInitialValueProps
  );

export const initialDropzoneState = {};

export const initialDropzoneStateIds = [];
