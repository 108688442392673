export const enum LightboxWrapperPermissions {
  READ_LIGHTBOX_TITLE_IMAGE_RESOLUTIONS = 'read_lightbox_title_image_resolutions',
  READ_LIGHTBOX_TITLE_IMAGE_DPI = 'read_lightbox_title_image_dpi',
  READ_LIGHTBOX_PLACEHOLDER_THUMBNAILS = 'read_lightbox_placeholder_thumbnails',
  READ_LIGHTBOX_DOWNLOAD_LIFESTYLES_BUTTON = 'read_lightbox_download_lifestyles_button',
  READ_LIGHTBOX_DOWNLOAD_PRODUCTS_BUTTON = 'read_lightbox_download_products_button',
  READ_LIGHTBOX_DOWNLOAD_MATERIALS_BUTTON = 'read_lightbox_download_materials_button',
  READ_LIGHTBOX_DOWNLOAD_BUTTON = 'read_lightbox_download_button',
  READ_LIGHTBOX_SEND_IMAGE_TO_WHITEBOARD_BUTTON = 'read_lightbox_send_image_to_whiteboard_button'
}

export const enum LightboxWrapperItemDownloadType {
  FILE_ATTACHMENTS = 'fileAttachments',
  LIFESTYLES = 'lifestyles',
  PRODUCTS = 'products',
  MATERIALS = 'materials'
}

export const LightboxWrapperItemDownloadPermission = {
  [LightboxWrapperItemDownloadType.LIFESTYLES]:
    LightboxWrapperPermissions.READ_LIGHTBOX_DOWNLOAD_LIFESTYLES_BUTTON,
  [LightboxWrapperItemDownloadType.PRODUCTS]:
    LightboxWrapperPermissions.READ_LIGHTBOX_DOWNLOAD_PRODUCTS_BUTTON,
  [LightboxWrapperItemDownloadType.MATERIALS]:
    LightboxWrapperPermissions.READ_LIGHTBOX_DOWNLOAD_MATERIALS_BUTTON,
  [LightboxWrapperItemDownloadType.FILE_ATTACHMENTS]:
    LightboxWrapperPermissions.READ_LIGHTBOX_DOWNLOAD_BUTTON
};
