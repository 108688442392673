import React, { useCallback } from 'react';

import {
  MessagesListMessageImageFileAttachmentAttachment,
  MessagesListMessageImageSelectedProductAttachment,
  MessagesListMessageImageSelectedLifestyleAttachment,
  MessagesListMessageImageSelectedMaterialAttachment,
  MessagesListMessageImageHandleOpenLightbox,
  MessagesListMessageImageMessage
} from './MessagesListMessageImage.types';
import {
  MessageAttachmentTypes,
  MessageFinAttachmentTypes
} from '../../../../../messagesTypes';

import { MessagesListMessageImageFileAttachment } from '../MessagesListMessageImageFileAttachment';
import { MessagesListMessageImageLifestyle } from '../MessagesListMessageImageLifestyle';
import { MessagesListMessageImageMaterial } from '../MessagesListMessageImageMaterial';
import { MessagesListMessageImageProduct } from '../MessagesListMessageImageProduct';

import { OnLightboxOpenAction } from '../../../../../../../helpers/LightboxWrapper';

import { Files } from '../../../../../../../utils/Files';
import { mapMessageAttachmentImage } from '../../../../../utils/mapMessageAttachmentImage';

const imageClassName =
  'w-full h-full rounded object-cover bg-gray-100 dark:bg-gray-850 break-words';

const linkClassName =
  'h-16 relative rounded bg-gray-100 dark:bg-gray-850 group/item w-16';

interface MessagesListMessageImageProps {
  attachment:
    | MessagesListMessageImageFileAttachmentAttachment
    | MessagesListMessageImageSelectedProductAttachment
    | MessagesListMessageImageSelectedLifestyleAttachment
    | MessagesListMessageImageSelectedMaterialAttachment;
  message: MessagesListMessageImageMessage;
  onLightboxOpen: OnLightboxOpenAction;
}

function MessagesListMessageImage({
  attachment,
  message,
  onLightboxOpen
}: MessagesListMessageImageProps) {
  const handleOpenLightbox =
    useCallback<MessagesListMessageImageHandleOpenLightbox>(
      (e) => {
        const lightboxImage = mapMessageAttachmentImage(attachment);
        if (lightboxImage && Files.isImage(lightboxImage?.image?.file)) {
          e.preventDefault();
          onLightboxOpen(lightboxImage);
        }
      },
      [attachment, onLightboxOpen]
    );

  if (
    attachment.type === MessageAttachmentTypes.FILE_ATTACHMENT ||
    attachment.type === MessageFinAttachmentTypes.FILE_ATTACHMENT
  ) {
    if (!Files.isImage(attachment.file)) {
      return null;
    }

    return (
      <MessagesListMessageImageFileAttachment
        linkClassName={linkClassName}
        imageClassName={imageClassName}
        attachment={attachment}
        message={message}
        handleOpenLightbox={handleOpenLightbox}
        tooltipSingleton
      />
    );
  }

  if (
    (attachment.type === MessageAttachmentTypes.SELECTED_PRODUCT ||
      attachment.type === MessageFinAttachmentTypes.SELECTED_PRODUCT) &&
    attachment.product &&
    attachment.product.image
  ) {
    return (
      <MessagesListMessageImageProduct
        linkClassName={linkClassName}
        imageClassName={imageClassName}
        attachment={attachment}
        handleOpenLightbox={handleOpenLightbox}
        tooltipSingleton
      />
    );
  }

  if (
    (attachment.type === MessageAttachmentTypes.SELECTED_LIFESTYLE ||
      attachment.type === MessageFinAttachmentTypes.SELECTED_LIFESTYLE) &&
    attachment.lifestyle &&
    attachment.lifestyle.image
  ) {
    return (
      <MessagesListMessageImageLifestyle
        linkClassName={linkClassName}
        imageClassName={imageClassName}
        attachment={attachment}
        handleOpenLightbox={handleOpenLightbox}
        tooltipSingleton
      />
    );
  }

  if (
    (attachment.type === MessageAttachmentTypes.SELECTED_MATERIAL ||
      attachment.type === MessageFinAttachmentTypes.SELECTED_MATERIAL) &&
    attachment.material &&
    attachment.material.image
  ) {
    return (
      <MessagesListMessageImageMaterial
        linkClassName={linkClassName}
        imageClassName={imageClassName}
        attachment={attachment}
        handleOpenLightbox={handleOpenLightbox}
        tooltipSingleton
      />
    );
  }

  return null;
}

export default MessagesListMessageImage;
