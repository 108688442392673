import React, { ReactNode } from 'react';

import { ClassName, GADataType } from '../../../../types';

import { UserLinkLinkType, UserLinkUser } from './UserLink.types';

import { useCurrentUser } from '../../../../auth/hooks/useAuth';
import { useThirdPersonView } from '../../../../app/hooks/useThirdPersonView';

import { NextLinkHelper } from '../../../../helpers/links/NextLinkHelper';

import { UserPath } from '../../../users/UserPath';
import { TeamPath } from '../../../teams/TeamPath';

import { UsersPermissions } from '../../../users/usersConstants';

interface UserLinkDefaultProps {
  user: UserLinkUser;
  className?: ClassName;
  withoutTitle?: boolean;
  linkType?: UserLinkLinkType;
}

interface UserLinkWithChildrenProps {
  text?: never;
  children: ReactNode;
}

interface UserLinkWithTextProps {
  text: string;
  children?: never;
}

type UserLinkProps = UserLinkDefaultProps &
  (UserLinkWithChildrenProps | UserLinkWithTextProps);

function UserLink({
  className,
  withoutTitle,
  user,
  linkType = 'companyOrUser',
  text,
  children,
  dataGa
}: UserLinkProps & GADataType) {
  const currentUser = useCurrentUser();

  const { isThirdPersonView, companyNanoId } = useThirdPersonView();

  if (!user) {
    return null;
  }

  const userLink = UserPath.show(user.nanoId);
  const clientLink =
    user.client && user.currentTeam?.nanoId
      ? TeamPath.companyUserProfile(user.currentTeam?.nanoId, user.nanoId)
      : null;
  const companyLink = user.currentTeam?.nanoId
    ? TeamPath.companyProfile(user.currentTeam.nanoId)
    : null;

  const companyOrUser = clientLink || companyLink || userLink;

  const companyUser =
    isThirdPersonView && companyNanoId
      ? TeamPath.companyUserProfile(companyNanoId, user.nanoId)
      : companyOrUser;

  const hrefs = {
    user: userLink,
    companyOrUser,
    companyUser
  };

  const canReadLink = currentUser.hasPermissions(
    UsersPermissions.READ_USER_PROFILE_LINK
  );

  const canOpenInTheSameTab = currentUser.hasPermissions(
    UsersPermissions.OPEN_USER_PROFILE_LINK_IN_THE_SAME_TAB
  );

  return (
    <NextLinkHelper
      className={className}
      dataGa={dataGa}
      href={canReadLink ? hrefs[linkType] : '#'}
      target={canOpenInTheSameTab || !canReadLink ? undefined : '_blank'}
      title={withoutTitle ? null : user.fullName}
    >
      {text || children}
    </NextLinkHelper>
  );
}

export default UserLink;
