import { FetchFileAttachmentsSortTypes } from './fileAttachmentsTypes';

export const INITIAL_FILE_ATTACHMENTS_FILTERS = {};
export const INITIAL_FILE_ATTACHMENTS_PAGE = 1;
export const INITIAL_FILE_ATTACHMENTS_SORT = [
  FetchFileAttachmentsSortTypes.CREATED_AT_DESC
];
export const INITIAL_FILE_ATTACHMENTS_LIMIT = 50;

export const enum FileAttachmentTypesEnum {
  ATTACHMENTS = 'attachments',
  DRAWING = 'drawing',
  FILES = 'files',
  IMAGES = 'images',
  LD = 'ld',
  LIFESTYLES = 'lifestyles',
  MATERIALS = 'materials',
  PRODUCTS = 'products',
  REFERENCE = 'reference',
  SOURCE_FILES = 'sourceFiles',
  TEXTURE = 'texture',
  THREED = 'threeD'
}

export const enum FileAttachmentsPermissions {
  READ_DROPZONE_FILE_ATTACHMENT_LD_TAB = 'read_dropzone_file_attachment_ld_tab'
}
