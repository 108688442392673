import React, { memo, Fragment, useMemo, useCallback } from 'react';
import filter from 'lodash/filter';

import { IsFetched, ErrorMessage } from '../../../../../types';

import { TaskID } from '../../../../tasks/tasksTypes';
import { ProjectID } from '../../../../projects/projectsTypes';
import { FetchMessagesCacheKey } from '../../../../messages/messagesTypes';

import {
  FetchProductsCacheKeys,
  UpdateProductCacheAction,
  ProductNanoID
} from '../../../productsTypes';

import { ProductsBlockListItem } from '../ProductsBlockListItem';

import { ProductsBlockListItemLightboxBottomButtons } from './components/ProductsBlockListItemLightboxBottomButtons';
import { ProductsBlockListLoadingGridSkeleton } from './components/ProductsBlockListLoadingGridSkeleton';
import { ProductsListItemLightboxTitle } from '../ProductsList/components/ProductsListItemLightboxTitle';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { NoResults } from '../../../../../helpers/NoResults';
import {
  LightboxToggleBackdrop,
  LightboxWrapper,
  useLightboxWrapper,
  LightboxRenderCustomButtons,
  LightboxRenderImageTitle
} from '../../../../../helpers/LightboxWrapper';

import { Files } from '../../../../../utils/Files';

import {
  ProductsBlockListProducts,
  ProductsBlockListProduct
} from './ProductsBlockList.types';
interface ProductsBlockListProps {
  products: ProductsBlockListProducts;
  productsFetched: IsFetched;
  productsError: ErrorMessage;
  productsIsPlaceholderData: boolean;
  productsTotalCount: number;
  productsCacheKeys?: FetchProductsCacheKeys;
  loadingItemsCount?: number;
  projectId?: ProjectID;
  taskId?: TaskID;
  messagesCacheKeys?: FetchMessagesCacheKey[];
  updateProductCache: UpdateProductCacheAction<ProductsBlockListProduct>;
  togglePreventModalClose?: LightboxToggleBackdrop;
  onProductEditButtonMouseEnter?: (productNanoId: ProductNanoID) => void;
}

function ProductsBlockList({
  products,
  productsFetched,
  productsError,
  productsIsPlaceholderData,
  productsTotalCount,
  productsCacheKeys,
  loadingItemsCount,
  projectId,
  taskId,
  messagesCacheKeys,
  updateProductCache,
  togglePreventModalClose,
  onProductEditButtonMouseEnter
}: ProductsBlockListProps) {
  const productsInLightbox = useMemo<ProductsBlockListProducts>(() => {
    return filter<ProductsBlockListProduct>(
      products,
      (product) => product.image?.file && Files.isImage(product.image.file)
    );
  }, [products]);

  const {
    handleLightboxClose,
    handleLightboxNext,
    handleLightboxOpen,
    handleLightboxOpenOnSlide,
    handleLightboxPrev,
    index,
    imagesCount,
    imageItem,
    lightBoxOpened,
    mainSrc,
    prevSrc,
    nextSrc
  } = useLightboxWrapper({
    items: productsInLightbox,
    toggleBackdrop: togglePreventModalClose
  });

  const renderLightboxButtons = useCallback<LightboxRenderCustomButtons>(
    ({ index }) => {
      const product = productsInLightbox[index];
      if (!product) {
        return [];
      }

      return (
        <ProductsBlockListItemLightboxBottomButtons
          product={product}
          productsCacheKeys={productsCacheKeys}
          updateProductCache={updateProductCache}
        />
      );
    },
    [productsInLightbox, productsCacheKeys, updateProductCache]
  );

  const renderImageTitle = useCallback<LightboxRenderImageTitle>(
    ({ index }) => {
      const product = productsInLightbox[index];
      if (!product) {
        return null;
      }

      return <ProductsListItemLightboxTitle product={product} />;
    },
    [productsInLightbox]
  );

  return (
    <Fragment>
      <AlertMessage addClassName="m-4" message={productsError} />
      <ProductsBlockListLoadingGridSkeleton
        itemsCount={loadingItemsCount}
        loaded={productsIsPlaceholderData || productsFetched}
      >
        {productsTotalCount === 0 ? (
          <NoResults addErrorClassName="m-4" />
        ) : (
          <div className="grid grid-cols-auto-fill grid-cell-min-36 sm:grid-cell-min-40 gap-2">
            {products.map((product) => (
              <ProductsBlockListItem
                key={product.uuid}
                product={product}
                productsCacheKeys={productsCacheKeys}
                projectId={projectId}
                taskId={taskId}
                messagesCacheKeys={messagesCacheKeys}
                onLightboxOpen={handleLightboxOpenOnSlide}
                updateProductCache={updateProductCache}
                onProductEditButtonMouseEnter={onProductEditButtonMouseEnter}
              />
            ))}
          </div>
        )}
      </ProductsBlockListLoadingGridSkeleton>

      <LightboxWrapper
        handleLightboxClose={handleLightboxClose}
        handleLightboxNext={handleLightboxNext}
        handleLightboxOpen={handleLightboxOpen}
        handleLightboxPrev={handleLightboxPrev}
        index={index}
        imagesCount={imagesCount}
        imageItem={imageItem}
        lightBoxOpened={lightBoxOpened}
        mainSrc={mainSrc}
        nextSrc={nextSrc}
        prevSrc={prevSrc}
        renderImageTitle={renderImageTitle}
        renderCustomButtons={renderLightboxButtons}
        withFullScreenButton
      />
    </Fragment>
  );
}

export default memo<ProductsBlockListProps>(ProductsBlockList);
