import { useCallback, useMemo, useReducer, useEffect, useRef } from 'react';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isFinite from 'lodash/isFinite';
import size from 'lodash/size';

import {
  LightboxItem,
  LightboxItemImage,
  LightboxItems,
  LightboxThumbnails,
  LightboxToggleBackdrop,
  OnLightboxOpenAction
} from './LightboxWrapper.types';

import { Files } from '../../utils/Files';

interface LightboxState {
  index: number;
  count: number;
  lightBoxOpened: boolean;
}

const initialState: LightboxState = {
  index: 0,
  count: 0,
  lightBoxOpened: false
};

const LIGHTBOX_OPEN = 'LIGHTBOX_OPEN';
const LIGHTBOX_CLOSE = 'LIGHTBOX_CLOSE';
const LIGHTBOX_PREV = 'LIGHTBOX_PREV';
const LIGHTBOX_NEXT = 'LIGHTBOX_NEXT';
const LIGHTBOX_ITEMS_COUNT_DECREASE = 'LIGHTBOX_ITEMS_COUNT_DECREASE';

type LightboxAction =
  | {
      type: 'LIGHTBOX_OPEN';
      index: number;
      count: number;
    }
  | {
      type: 'LIGHTBOX_CLOSE';
    }
  | {
      type: 'LIGHTBOX_NEXT';
    }
  | {
      type: 'LIGHTBOX_PREV';
    }
  | {
      type: 'LIGHTBOX_ITEMS_COUNT_DECREASE';
      count: number;
    };

function lightboxReducer(state: LightboxState, action: LightboxAction) {
  switch (action.type) {
    case LIGHTBOX_OPEN:
      return {
        ...state,
        index: action.index,
        count: action.count,
        lightBoxOpened: true
      };

    case LIGHTBOX_CLOSE:
      return {
        ...state,
        lightBoxOpened: false
      };

    case LIGHTBOX_NEXT:
      return {
        ...state,
        index: (state.index + 1) % state.count
      };

    case LIGHTBOX_PREV:
      return {
        ...state,
        index: (state.index + state.count - 1) % state.count
      };

    case LIGHTBOX_ITEMS_COUNT_DECREASE:
      return {
        ...state,
        index: state.index % action.count,
        count: action.count
      };
  }

  return state;
}

interface LightboxWrapperOptions {
  items: LightboxItems;
  thumbnails?: LightboxThumbnails;
  toggleBackdrop?: LightboxToggleBackdrop;
}

function useLightboxWrapper({
  items,
  thumbnails,
  toggleBackdrop
}: LightboxWrapperOptions) {
  const [{ index, lightBoxOpened, count }, dispatch] = useReducer(
    lightboxReducer,
    initialState
  );

  const imageItems = useMemo<LightboxItems>(
    () =>
      compact(
        filter<LightboxItem>(items, (item: LightboxItem) =>
          Files.isImage(item.image?.file)
        )
      ),
    [items]
  );

  const imageItemsRef = useRef<LightboxItems>(imageItems);
  imageItemsRef.current = imageItems;

  const mainImageIndex = index;
  const nextImageIndex = (index + 1) % imageItems.length;
  const prevImageIndex = (index + imageItems.length - 1) % imageItems.length;

  const handleLightboxOpen = useCallback(
    (openIndex: number) => {
      dispatch({
        type: LIGHTBOX_OPEN,
        index: openIndex,
        count: imageItemsRef.current.length
      });
      toggleBackdrop && toggleBackdrop();
    },
    [toggleBackdrop, dispatch]
  );

  const handleLightboxOpenOnSlide = useCallback<OnLightboxOpenAction>(
    (item: LightboxItem) => {
      handleLightboxOpen(
        findIndex<LightboxItem>(
          imageItemsRef.current,
          (imageItem) =>
            imageItem.uuid === item.uuid &&
            imageItem.image?.uuid === item.image?.uuid
        )
      );
    },
    [handleLightboxOpen]
  );

  const handleLightboxClose = useCallback(() => {
    dispatch({ type: LIGHTBOX_CLOSE });
    toggleBackdrop && toggleBackdrop();
  }, [toggleBackdrop, dispatch]);

  const handleLightboxPrev = useCallback(
    () => dispatch({ type: LIGHTBOX_PREV }),
    [dispatch]
  );

  const handleLightboxNext = useCallback(
    () => dispatch({ type: LIGHTBOX_NEXT }),
    [dispatch]
  );

  useEffect(() => {
    if (lightBoxOpened && size(imageItems) < count) {
      dispatch({
        type: LIGHTBOX_ITEMS_COUNT_DECREASE,
        count: size(imageItems)
      });
    }
  }, [lightBoxOpened, count, imageItems, dispatch]);

  return {
    index: mainImageIndex,
    imageItem: imageItems[mainImageIndex],
    mainSrc: isFinite(mainImageIndex)
      ? get<LightboxItemImage, 'file'>(
          imageItems[mainImageIndex]?.image,
          'file'
        )
      : undefined,
    nextSrc:
      isFinite(nextImageIndex) && imageItems.length > 1
        ? get<LightboxItemImage, 'file'>(
            imageItems[nextImageIndex]?.image,
            'file'
          )
        : undefined,
    prevSrc:
      isFinite(prevImageIndex) && imageItems.length > 1
        ? get<LightboxItemImage, 'file'>(
            imageItems[prevImageIndex]?.image,
            'file'
          )
        : undefined,
    mainSrcThumbnail:
      isFinite(mainImageIndex) && !isEmpty(thumbnails)
        ? thumbnails[mainImageIndex]
        : undefined,
    prevSrcThumbnail:
      isFinite(nextImageIndex) && imageItems.length > 1 && !isEmpty(thumbnails)
        ? thumbnails[nextImageIndex]
        : undefined,
    nextSrcThumbnail:
      isFinite(prevImageIndex) && imageItems.length > 1 && !isEmpty(thumbnails)
        ? thumbnails[prevImageIndex]
        : undefined,
    lightBoxOpened,
    handleLightboxOpen,
    handleLightboxOpenOnSlide,
    handleLightboxClose,
    handleLightboxPrev,
    handleLightboxNext,
    imagesCount: imageItems.length
  };
}

export default useLightboxWrapper;
