import React, { useCallback, useMemo } from 'react';
import size from 'lodash/size';
import sortBy from 'lodash/sortBy';

import { MessagesListMessageImagesMessage } from './MessagesListMessageImages.types';
import {
  MessageAttachmentTypes,
  MessageFinAttachmentTypes
} from '../../../../../messagesTypes';
import { TogglePreventModalCloseAction } from '../../../../../../../helpers/modals/modalsTypes';
import { FileAttachmentItemImageVersions } from '../../../../../../../helpers/ImageHelper';

import { MessagesListMessageImage } from '../MessagesListMessageImage';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';
import { useImageEditorSubmitWithItemNanoId } from '../../../../../../../helpers/ImageEditor/hooks/useImageEditorSubmitWithItemNanoId';

import {
  LightboxRenderImageTitle,
  LightboxWrapper,
  useLightboxWrapper,
  LightboxThumbnails
} from '../../../../../../../helpers/LightboxWrapper';
import { ModelLightboxTitle } from '../../../../../../common/helpers/ModelLightboxTitle';
import { Files } from '../../../../../../../utils/Files';

import { LightboxWrapperPermissions } from '../../../../../../../helpers/LightboxWrapper/lightboxWrapperConstants';
import { MessagesPermissions } from '../../../../../messagesConstants';
import { ModelLightboxTitleAttachment } from '../../../../../../common/helpers/ModelLightboxTitle/ModelLightboxTitle';
import { mapMessageAttachmentImage } from '../../../../../utils/mapMessageAttachmentImage';

interface MessagesListMessageImagesProps {
  message: MessagesListMessageImagesMessage;
  togglePreventModalClose?: TogglePreventModalCloseAction;
}

function MessagesListMessageImages({
  message,
  togglePreventModalClose
}: MessagesListMessageImagesProps) {
  const currentUser = useCurrentUser();

  const attachments = useMemo(() => {
    const fileAttachments =
      message.fileAttachments?.filter((fileAttachment) =>
        Files.isImage(fileAttachment.file)
      ) || [];
    const selectedProducts = message.selectedProducts || [];
    const selectedLifestyles = message.selectedLifestyles || [];
    const selectedMaterials = message.selectedMaterials || [];
    return sortBy(
      [
        ...fileAttachments,
        ...selectedProducts,
        ...selectedLifestyles,
        ...selectedMaterials
      ],
      (attachment) => attachment.createdAt
    );
  }, [message]);

  const lightboxItems = useMemo(() => {
    return attachments.map(mapMessageAttachmentImage);
  }, [attachments]);

  const lightboxThumbnails = useMemo<LightboxThumbnails>(() => {
    return attachments.map((attachment) =>
      attachment.type === MessageAttachmentTypes.FILE_ATTACHMENT
        ? Files.urlFromFile(
            attachment.file,
            FileAttachmentItemImageVersions.FullScreenThumb1000x850
          )
        : null
    );
  }, [attachments]);

  const {
    handleLightboxClose,
    handleLightboxNext,
    handleLightboxOpen,
    handleLightboxOpenOnSlide,
    handleLightboxPrev,
    index,
    imagesCount,
    imageItem,
    lightBoxOpened,
    mainSrc,
    prevSrc,
    nextSrc,
    mainSrcThumbnail,
    prevSrcThumbnail,
    nextSrcThumbnail
  } = useLightboxWrapper({
    items: lightboxItems,
    thumbnails: currentUser.hasPermissions(
      LightboxWrapperPermissions.READ_LIGHTBOX_PLACEHOLDER_THUMBNAILS
    )
      ? lightboxThumbnails
      : null,
    toggleBackdrop: togglePreventModalClose
  });

  const { handleImageEditorSubmitWithItemNanoId } =
    useImageEditorSubmitWithItemNanoId(
      message.project?.nanoId
        ? { projectNanoId: message.project.nanoId }
        : { taskNanoId: message.task?.nanoId }
    );

  const currentLightboxImageAttachment = attachments[index];

  const attachmentsTotalCount = size(attachments);

  const renderImageTitle = useCallback<LightboxRenderImageTitle>(() => {
    if (!currentLightboxImageAttachment) {
      return null;
    }

    if (
      currentLightboxImageAttachment.type !==
        MessageAttachmentTypes.FILE_ATTACHMENT &&
      currentLightboxImageAttachment.type !==
        MessageFinAttachmentTypes.FILE_ATTACHMENT
    ) {
      return (
        <ModelLightboxTitle
          attachment={
            currentLightboxImageAttachment as ModelLightboxTitleAttachment
          }
        />
      );
    }
  }, [currentLightboxImageAttachment]);

  if (attachmentsTotalCount === 0) {
    return null;
  }

  return (
    <>
      {attachments.map((attachment) => (
        <MessagesListMessageImage
          key={attachment.uuid}
          attachment={attachment}
          message={message}
          onLightboxOpen={handleLightboxOpenOnSlide}
        />
      ))}

      <LightboxWrapper
        handleLightboxClose={handleLightboxClose}
        handleLightboxNext={handleLightboxNext}
        handleLightboxOpen={handleLightboxOpen}
        handleLightboxPrev={handleLightboxPrev}
        index={index}
        imagesCount={imagesCount}
        imageItem={imageItem}
        lightBoxOpened={lightBoxOpened}
        mainSrc={mainSrc}
        nextSrc={nextSrc}
        prevSrc={prevSrc}
        mainSrcThumbnail={mainSrcThumbnail}
        nextSrcThumbnail={nextSrcThumbnail}
        prevSrcThumbnail={prevSrcThumbnail}
        withFullScreenButton
        renderImageTitle={
          currentLightboxImageAttachment?.type !==
            MessageAttachmentTypes.FILE_ATTACHMENT &&
          currentLightboxImageAttachment?.type !==
            MessageFinAttachmentTypes.FILE_ATTACHMENT
            ? renderImageTitle
            : undefined
        }
        withBaseImageTitle={
          currentLightboxImageAttachment?.type ===
            MessageAttachmentTypes.FILE_ATTACHMENT ||
          currentLightboxImageAttachment?.type ===
            MessageFinAttachmentTypes.FILE_ATTACHMENT
        }
        toggleImageEditorBackdrop={togglePreventModalClose}
        onImageEditSubmit={
          (currentLightboxImageAttachment?.type ===
            MessageAttachmentTypes.FILE_ATTACHMENT ||
            currentLightboxImageAttachment?.type ===
              MessageFinAttachmentTypes.FILE_ATTACHMENT) &&
          currentUser.hasPermissions(
            MessagesPermissions.READ_MESSAGE_FILE_ATTACHMENT_EDIT_BUTTON
          )
            ? handleImageEditorSubmitWithItemNanoId
            : undefined
        }
      />
    </>
  );
}

export default MessagesListMessageImages;
