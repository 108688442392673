import { TaskNanoID } from '../../tasksTypes';

import { useTask } from '../useTask';

import {
  FETCH_SHOW_TASK_QUERY,
  FetchShowTaskQueryResponse
} from '../../queries/fetchShowTask.query';

import { TaskCache } from '../../TaskCache';

interface TaskShowPageOptions {
  taskNanoId: TaskNanoID;
  options?: {
    cacheTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
  };
}

function useTaskShowPage({ taskNanoId, options = {} }: TaskShowPageOptions) {
  return useTask<FetchShowTaskQueryResponse>({
    tasks: [],
    cacheKey: TaskCache.showCacheKey(),
    query: FETCH_SHOW_TASK_QUERY,
    uuid: taskNanoId,
    options
  });
}

export default useTaskShowPage;
